export default [
  {
    icon: 'FileIcon',
    children: [
      {
        title: 'Home',
        route: 'dashboard',
        icon: 'HomeIcon',
      },

      { header: 'Users' },
      {
        title: 'Users',
        route: 'users',
        icon: 'UserIcon',
      },
    ],
  },
]
